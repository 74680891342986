<template>
  <page :name="$t('Listino prezzi')" :empty="products.length == 0" :state="asyncComputed">
    <pf-toolbar ref="toolbar" view="list" views="" style="position:sticky; top: 60px; z-index:1">
      <template #filter>
        <input v-model="q" type="search" :placeholder="$t('Filtra')" class="form-control search-query">
      </template>

      <currency-toggle v-if="!login.currency_locked" id="currency-dropdown" :prefix="$t('Valuta')" />

      <pf-dropdown id="invoicing-dropdown" menu-right :text="$t('Fatturazione') + ': ' + $t(selectDurata)">
        <li>
          <a role="menuitem" @click.prevent="selectDurata = 'Annuale'">
            {{ $t('Annuale') }}
          </a>
        </li>
        <li>
          <a role="menuitem" @click.prevent="selectDurata = 'Mensile'">
            {{ $t('Mensile') }}
          </a>
        </li>
      </pf-dropdown>
    </pf-toolbar>

    <section v-for="category of sortedCategories" :key="category" style="margin-top: 1em">
      <h2 v-if="category">
        {{ category.replace(/(^| — )\d+\.\s*/g, '$1') }}
      </h2>

      <product-list
        :items="catalog[category]"
        :currency="login.currency"
        :hide-plus-vat="login.iva < 0.01"
        :disable-ordina="!login.enable_orders"
      />
    </section>
  </page>
</template>

<script lang="ts">
import { setupAsyncComputed } from '@common/asyncComputed';
import { any } from '@common/utils';

import ProductList from '../components/product-list.vue';
import Page from '../components/page.vue';
import CurrencyToggle from '../components/currency-toggle.vue';
import { useLoginStore } from '@/store/login';
import { CatalogResource, type CatalogProduct } from '@/resources';
import { defineComponent } from 'vue';

type NormalizedCatalogProduct = CatalogProduct & {
  prezzo: number;
  prezzo_scontato: number;
  prezzo_ivato: number;
};

export default defineComponent({
  name: 'CatalogPage',

  components: {
    Page,
    ProductList,
    CurrencyToggle,
  },

  setup() {
    const login = useLoginStore();
    return {
      login,
      ...setupAsyncComputed({
        products: {
          get() {
            return new CatalogResource({id_azienda: Number(login.id_azienda)}).get();
          },
          default: [] as CatalogProduct[],
        },
      }),
    };
  },

  data() {
    return {
      q: '', // search query
      selectDurata: 'Annuale',
    };
  },

  computed: {
    sortedCategories() {
      const categories = Object.keys(this.catalog);
      categories.sort();
      return categories;
    },

    catalog() {
      const categorized: Record<string, NormalizedCatalogProduct[]> = {};

      const filter = any(this.q);
      for (const item of this.products) {
        if (filter(item) && this.filtroDurata(item)) {
          const category = item.categoria ?? '';

          if (!Object.prototype.hasOwnProperty.call(categorized, category)) {
            categorized[category] = [];
          }

          categorized[category].push({
            ...item,
            prezzo: this.login.currency == 'EUR' ? item.prezzo_eur : item.prezzo_chf,
            prezzo_scontato: this.login.currency == 'EUR' ? item.prezzo_scontato_eur : item.prezzo_scontato_chf,
            prezzo_ivato: this.login.currency == 'EUR' ? item.prezzo_ivato_eur : item.prezzo_ivato_chf,
          });
        }
      }
      return categorized;
    },
  },

  methods: {
    filtroDurata(prodotto: CatalogProduct) {
      return prodotto.durata == 'Una tantum' || prodotto.durata == this.selectDurata;
    },
  },
});
</script>
