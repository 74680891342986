import axios from "axios";
import type { ComputedRef, InjectionKey } from "vue";
import { http } from "./http";
import { PRODOTTIANAGRAFICA_MONITORING, REQUIREMENTTYPE, OPPORTUNITA_STATO } from './common/consts';

export type ResourceId = number | string;
function isResourceId(id: any): id is ResourceId {
  return typeof id === 'string' || typeof id === 'number';
}

export type GenericResource = Record<string, any>;

export type ResourceData<T> = FormData | (T & { id?: ResourceId; });
function isResourceData<T>(data: any): data is ResourceData<T> {
  return data instanceof FormData || typeof data === 'object';
}

export type QueryParams = Record<string, string | number>;

export class Resource<GetT = GenericResource, GetListT = GetT[], PutT = Partial<GetT>, PostT = Partial<GetT>> {
  model: string;
  basepath: string;
  queryParams: QueryParams;

  constructor(model: string, queryParams: QueryParams = {}, basepath = 'rest') {
    this.model = model;
    this.queryParams = queryParams;
    this.basepath = basepath;
  }

  async get(): Promise<GetListT>;
  async get(id: ResourceId, default404?: GetT): Promise<GetT>;
  async get(id?: ResourceId, default404?: GetT): Promise<any> {
    try {
      return (await http.get(this.url(id))).data;
    } catch (e: any) {
      if (default404 !== null && typeof default404 !== 'undefined' && axios.isAxiosError(e) && e.response?.status === 404) {
        return default404;
      }
      throw e;
    }
  }

  processResourceDataFiles(data: ResourceData<PostT | PutT>) {
    if (!isResourceData(data)) {
      return data;
    }
    if (!Object.values(data).some(v => v instanceof Blob || v instanceof FileList)) {
      return data;
    }

    const formdata = new FormData();
    const jsonData: GenericResource = {};

    for (const [k, v] of Object.entries(data)) {
      if (v instanceof Blob) {
        formdata.append(k, v);
      } else if (v instanceof FileList) {
        for (const f of v) {
          formdata.append(`${k}[]`, f);
        }
      } else {
        jsonData[k] = v;
      }
    }

    formdata.append('json', JSON.stringify(jsonData));
    return formdata;
  }

  async post(data: ResourceData<PostT>) {
    return (await http.post(this.url(), this.processResourceDataFiles(data))).data;
  }

  async put(id: ResourceId, data: ResourceData<PutT>) {
    return (await http.put(this.url(id), this.processResourceDataFiles(data))).data;
  }

  async delete(id: ResourceId) {
    return (await http.delete(this.url(id))).data;
  }

  save(id?: ResourceId | ResourceData<PostT> | null, data: ResourceData<PostT> | null = null) {
    if (isResourceData(id)) {
      data = id;
      id = null;
    }

    if (data === null) {
      throw new Error('missing data');
    }

    if (typeof id === 'undefined' || id === null) {
      if (data instanceof FormData) {
        const formId = data.get('id');
        if (!(formId instanceof File)) {
          id = formId;
        }
      } else {
        id = data.id;
      }
    }

    if (isResourceId(id)) {
      return this.put(id, data as ResourceData<PutT>);
    }
    return this.post(data);
  }

  url(id?: ResourceId) {
    if (Array.isArray(id)) {
      id = id.join('-');
    }
    id = typeof id === 'undefined' || id === null ? '' : `/${id}`;
    return `/${this.basepath}/${this.model}${id}${this.querystring}`;
  }

  child(id: ResourceId, model: string) {
    let url = this.url(id);
    url = url.substring(1, Math.max(url.length - this.querystring.length, 0) + 1);
    return new Resource(model, this.queryParams, url);
  }

  get querystring() {
    const q = this.queryParams;
    const keys = Object.keys(q).filter(k => typeof q[k] != 'undefined');
    if (!keys.length) {
      return '';
    }
    return `?${keys.map(k => `${k}=${encodeURIComponent(q[k])}`).join('&')}`;
  }
}

export type MessageResponse = {
  message: string;
  level: string;
}

export type PHPDateTime = {
  date: string;
  timezone: string;
  timezone_type: number;
}

export type ProductOrder = {
  id: number;
  id_ordine: number;
  dominio: string | null;
  qt: number;
  prezzo: string;
  sconto_minimo: string;
  free_period: number;
  stato_tecnico: string;
  data_fine: string;
  nome: string;
  rinnovo_automatico: boolean;
  disdetto: boolean;
  durata: string;
  n_upgrades: number;
  scaduto: boolean;
  note_cliente: string | null;
  last_bw_upgrade: string;
  free_bw_upgrade: string;
  bw_upgrade_price: number;
  registrazione_dominio: boolean;
  codice: string;
  missing_data: boolean;
}

export type ProductOrderDetail = {
  id: number;
  id_ordine: number;
  dominio: string|null;
  qt: number;
  prezzo: number;
  importo: number;
  valore_storno: number|null;
  upgradable_products: {
    id: number;
    codice: string;
    nome: string;
    durata: string;
    prezzo: number;
    importo: number;
    cicli: number;
  }[];
  sconto_minimo: number;
  free_period: number;
  stato_tecnico: string;
  stato_amministrativo: string;
  data_fine: string;
  codice: string;
  nome: string;
  rinnovo_automatico: number;
  descrizione: string;
}

export class ProductOrdersResource extends Resource<ProductOrderDetail[], ProductOrder[]> {
  constructor(queryParams: QueryParams = {}) {
    super('product_orders', queryParams);
  }
}

export type Order = {
  id: number;
  id_azienda: number;
  data: PHPDateTime;
  amount: number;
  total: number;
  vat: number;
  method: string;
  pagamento: string;
  stato: string;
  products: {
    id: number;
    codice: string;
    nome: string;
    qt: number;
    prezzo: number;
    dominio: string | null;
    stato_tecnico: string;
    registrazione_dominio: boolean;
    missing_data: boolean;
  }[];
}

export class OrdersResource extends Resource<Order> {
  constructor(queryParams: QueryParams = {}) {
    super('orders', queryParams);
  }
}

export type Payment = {
  amount: number;
  currency: 'EUR' | 'CHF';
  data: string;
  descrizione: string;
  fattura: string;
  id: number;
  id_fattura: number;
  pending: boolean;
  tipo: string;
}

export class PaymentsResource extends Resource<Payment> {
  constructor(queryParams: QueryParams = {}) {
    super('payments', queryParams);
  }
}

export type OfferProduct = {
  codice: string;
  dominio: string;
  durata: string;
  free_period: number;
  id: number;
  imponibile: number;
  importo: number;
  nome: string;
  note_fattura: string | null;
  qt: number;
  sconto: number;
  migratedFrom?: OfferProductMigratedFrom;
}

export type OfferProductMigratedFrom = {
  id: number;
  dominio: string|null;
  qt: number;
  note_fattura: string|null;
  importo: number;
  imponibile: number;
  nome: string;
  codice: string;
  durata: string;
  sconto: number;
  free_period: undefined;
}

export type OfferProductCancel = {
  id: number;
  dominio: string|null;
  qt: number;
  note_fattura: string|null;
  importo: number;
  imponibile: number;
  nome: string;
  codice: string;
  durata: string;
  sconto: number;
  free_period: number;
}

export type OfferTotals = {
  Annuale: {
    listino: number;
    scontato: number;
  };
  Mensile: {
    listino: number;
    scontato: number;
  };
  "Una tantum": {
    listino: number;
    scontato: number;
  };
}

export type Offer = {
  id: number;
  title: string;
  amount: string;
  currency: 'EUR' | 'CHF';
  data: string;
  gran_totale: number;
  gran_totale_ivato: number;
  id_azienda: number;
  id_reseller: number;
  note_offerta: string;
  prodotti: OfferProduct[];
  prodotti_disdire: OfferProductCancel[];
  ragione_sociale: string;
  sconto_custom: string;
  stato_offerta: 'inviata' | 'sollecitata' | 'rifiutata' | 'secondo_sollecito' | 'sollecito_rivenditore' | 'stand_by' | null;
  stato_ordine: string;
  free_period: number;
  testing_mode: 'rinnova' | 'disdici'
  titolo_offerta: string;
  totali: OfferTotals;
  totali_disdetti: OfferTotals;
  valore_storno: number;
}

export class OffersResource extends Resource<Offer, Offer[], { stato: 'rifiutata'; motivazione_rifiuto: 'prezzo'|'altro fornitore'|'tempistiche'|'non più necessario'|'altro'}> {
  constructor(queryParams: QueryParams = {}) {
    super('offers', queryParams);
  }
}

export type Contract = {
  id: number | null;
  nome: string;
  testo: string;
  titolo: string;
}

export class ContractResource extends Resource<Contract> {
  constructor(queryParams: QueryParams = {}) {
    super('contract', queryParams);
  }
}

export type Company = {
  ack_marketing: boolean;
  ack_privacy: boolean;
  ack_terms: boolean;
  cap: string;
  citta: string;
  cod_pagamento: string;
  codice_fiscale: string | null;
  contratto_cliente: boolean;
  currency: 'EUR' | 'CHF';
  currency_locked: boolean;
  email_pec: string | null;
  fatturante_strid: string;
  fatturazione_mensile: boolean;
  hasCompleteBillingData: boolean;
  id: number;
  id_pagamento: number;
  indirizzo: string;
  is_verified_billing: boolean;
  iva: string;
  lingua: string;
  locked: boolean;
  metodi_pagamento: string[];
  pagamento: string;
  partita_iva: string;
  provincia: string;
  ragione_sociale: string;
  sdi: string | null;
  stato: string;
  telefono: string | null;
  tipologia: string;
  use_credit: boolean;
  id_main_skill: number | null;
  requirements: number[];
  disableRecurringPayments: boolean;
  cashback_months: number[];
  approval: 'in approvazione cliente' | 'in approvazione rivenditore' | 'approvato' | null,
}

export const CompanyInjectionKey = Symbol('Company') as InjectionKey<Company | ComputedRef<Company>>;

export class CompanyResource extends Resource<Company> {
  constructor(queryParams: QueryParams = {}) {
    super('company', queryParams);
  }
}

export type Cashback = {
  amount: number;
}

export class CashbackResource extends Resource<Cashback> {
  constructor(queryParams: QueryParams = {}) {
    super('cashback', queryParams);
  }
}

export type DomainRegistrant = Pick<Company,
  'id' |
  'tipologia' |
  'ragione_sociale' |
  'stato' |
  'cap' |
  'indirizzo' |
  'citta' |
  'provincia' |
  'partita_iva' |
  'codice_fiscale'
> & {
  nome: string;
  cognome: string;
  authcode:string;
  usa_dati_fatturazione: boolean;
};

export class DomainRegistrantResource extends Resource<DomainRegistrant> {
  constructor(queryParams: QueryParams = {}) {
    super('domain_registrant', queryParams);
  }
}

export type Opportunity = {
  id: number;
  cliente?: string;
  data: string;
  nome: string;
  descrizione?: string,
  dominio?: string,
  tempistiche?: string,
  budget?: string,
  contatti?: string,
  skills: string[],
  tech_skills: string[],
  business_sectors: string[],
  stato: 'accettata'|'rifiutata'|'scaduta'|'attesa',
}

export class OpportunityResource extends Resource<Opportunity> {
  constructor(queryParams: QueryParams = {}) {
    super('opportunities', queryParams);
  }
}

export type Project = Omit<Opportunity, 'cliente'|'stato'> & {stato: typeof OPPORTUNITA_STATO[number], partner?: string|null};
export type ProjectCreate = Omit<Project, 'id'|'cliente'|'data'|'requisiti'|'stato'|'partner'> & {id_requirements: number[]};

export class ProjectResource extends Resource<Project, Project[], never, ProjectCreate> {
  constructor(queryParams: QueryParams = {}) {
    super('projects', queryParams);
  }
}

export type Ticket = {
  id: number | null;
  data: string | null;
  titolo: string;
  urgente: boolean;
  stato: 'aperto' | 'chiuso';
  nr_messaggi: number;
}

export type TicketCreate = {
  titolo: string;
  messaggio?: string
  urgente?: boolean;
  dettaglio?: string;
}

export class TicketResource extends Resource<Ticket, Ticket[], Partial<Ticket>, TicketCreate> {
  constructor(queryParams: QueryParams = {}) {
    super('tickets', queryParams);
  }

  messages(id: ResourceId) {
    let url = this.url(id);
    url = url.substring(1, Math.max(url.length - this.querystring.length, 0) + 1);
    return new TicketMessageResource(this.queryParams, url);
  }
}

export type TicketMessage = {
  id: number;
  messaggio: string;
  data: string;
  id_offerta_allegata: number | null;
  id_fattura_allegata: number | null;
  utente: string | null;
  operatore: string | null;
  nr_fattura: string | null;
  serie_fattura: string | null;
  anno_fattura: string | null;
  allegati: any[];
}

export class TicketMessageResource extends Resource<TicketMessage> {
  constructor(queryParams: QueryParams = {}, url: string) {
    super('messages', queryParams, url);
  }
}

export class UrgentTicketCostResource extends Resource<number> {
  constructor(queryParams: QueryParams = {}) {
    super('ticket_cost', queryParams);
  }
}

export class AreWeClosedResource extends Resource<number> {
  constructor(queryParams: QueryParams = {}) {
    super('are_we_closed', queryParams);
  }
}

export class CpanelLoginResource extends Resource<string> {
  constructor(queryParams: QueryParams = {}) {
    super('cpanel_login', queryParams);
  }
}

export class SSHKeysResource extends Resource<void, string[], string, string> {
  constructor(queryParams: QueryParams = {}) {
    super('ssh_keys', queryParams);
  }
}

export type Server = {
  id: number;
  nome: string;
  os: string | null;
  osrelease: string | null;
  fqdn: string | null;
  ip4_public: string | null;
  has_console: boolean;
  cpanel: string | null;
  server_location: string | null;
  backup_location: string | null;
}

export class ServerResource extends Resource<Server> {
  constructor(queryParams: QueryParams = {}) {
    super('servers', queryParams);
  }
}

export type ProductFeatures = {
  core: number | null,
  ram: number | null,
  spazio: number | null,
  traffico: number | null,
  sistema_operativo: string | null,
  pannello: string | null,
  servizio_email: boolean,
  registrazione_dominio: boolean,
  database: boolean,
  gestione_dns: boolean,
  backup: string | null,
  support: string | null,
  monitoring: typeof PRODOTTIANAGRAFICA_MONITORING[number] | null,
  utenti: number | null,
  nr_domini: number | null,
  ip_dedicato: string | null,
  redirect: boolean,
  ssl_certificate: boolean,
  ssh_access: boolean,
  imunify360: boolean,
  ddos_protection: boolean,
  waf: boolean,
  litespeed: boolean,
  ttfb: number | null,
  io: number | null,
  iops: number | null,
  ep: number | null,
  uptime: number | null,
  inode: boolean,
  descrizione: string | null,
}

export type CatalogProduct = ProductFeatures & {
  categoria: string | null,
  codice: string,
  durata: string,
  group_code: string | null,
  nome: string,
  nome_listino: string,
  prezzo_chf: number,
  prezzo_eur: number,
  prezzo_ivato_chf: number,
  prezzo_ivato_eur: number,
  prezzo_scontato_chf: number,
  prezzo_scontato_eur: number,
  richiesta_quantita: boolean,
  richiesto_dominio: boolean,
  tld: string | null,
  gestione: string | null,
}

export class CatalogResource extends Resource<CatalogProduct> {
  constructor(queryParams: QueryParams = {}) {
    super('catalog', queryParams);
  }
}

export type AccountMovementType = 'provvigioni' | 'segnalazioni' | 'comarketing' | 'cashback';

export type AccountMovement = {
  id: number;
  amount: number;
  dettagli: string;
  tipo: AccountMovementType;
  data: string;
}

export type AccountMovements = {
  movements: AccountMovement[];
  collect_from: number;
  referral_percentage: number;
  comarketing_percentage: number;
  cashback_percentage: number;
  cashback_until: string|null;
  cashback_months: number[];
  iban: string | null;
}

export class AccountMovementsResource extends Resource<void, AccountMovements> {
  constructor(queryParams: QueryParams = {}) {
    super('account_movements', queryParams);
  }
}

export type DnsRecord = {
  domain: string;
  ttl: string | null;
  type: string;
  weight: string | null;
  target: string;
}

export class DnsResource extends Resource<DnsRecord[], void, Partial<DnsRecord>[], Partial<DnsRecord>[]> {
  constructor(queryParams: QueryParams = {}) {
    super('dns', queryParams);
  }
}

export type CompanyAccess = {
  azienda: string;
  grants: string[];
}

export class CompanyAccessResource extends Resource<void, Record<number, CompanyAccess>> {
  constructor(queryParams: QueryParams = {}) {
    super('company_access', queryParams);
  }
}

export type MyCustomersAccess = {
  id: number;
  ragione_sociale: string;
  indirizzo: string|null;
  provincia: string|null;
  cap: string|null;
  citta: string|null;
  stato: string;
  partita_iva: string|null;
  currency: 'EUR' | 'CHF';
  currency_locked: boolean;
  vat: number;
  presented: boolean;
  enable_orders: boolean;
  contact_name: string|null;
  contact_mail: string|null;
}

export class MyCustomersResource extends Resource<void, MyCustomersAccess[]> {
  constructor(queryParams: QueryParams = {}) {
    super('my_customers', queryParams);
  }
}

export type Profile = {
  id: number;
  title: string|null;
  firstname: string|null;
  lastname: string|null;
  street: string|null;
  postalcode: string|null;
  city: string|null;
  province: string|null;
  country: string|null;
  phone: string|null;
  mobile: string|null;
  mail: string;
  newsletter_subscription: boolean;
  email_subscriptions: string[];
  grants: string[];
  twofa: boolean;
}

export class ProfilesResource extends Resource<Profile> {
  constructor(queryParams: QueryParams = {}) {
    super('profiles', queryParams);
  }
}
export type CreditDocument = {
  tipo: 'provvigioni' | 'comarketing';
  importo: number;
  messaggio: string;
  iban: string|null;
  allegati: FileList;
}

export class CreditDocumentsResource extends Resource<CreditDocument> {
  constructor(queryParams: QueryParams = {}) {
    super('credit_documents', queryParams);
  }
}

export type CompanyLogo = string;

export class CompanyLogoResource extends Resource<CompanyLogo, never, never, never> {
  constructor(queryParams: QueryParams = {}) {
    super('company_logo', queryParams);
  }
}

export type CompanyBrochure = string;

export class CompanyBrochureResource extends Resource<CompanyBrochure, never, never, never> {
  constructor(queryParams: QueryParams = {}) {
    super('company_brochure', queryParams);
  }
}

export type Introduction = {
  presentazione: string|null;
  has_logo: boolean;
  logo: File|null;
  has_brochure: boolean;
  brochure: File|null;
  categoria_prospect: string|null;
  dipendenti: string|null;
  anni_attivita: string|null;
  fatturato: string|null;
  clienti: string|null;
  dimensione_clienti: string|null;
  associazioni: string|null;
  preferenza_fatturazione: string|null;
  admin_pros: string|null;
  admin_cons: string|null;
  admin_feature_request: string|null;
  reseller_allow_newsletter: boolean;
  reseller_allow_contact: boolean;
  receive_opportunity: boolean;
}

export type IntroductionCreate = Omit<Introduction, 'logo'|'brochure'>

export class IntroductionResource extends Resource<Introduction, Introduction[], IntroductionCreate, IntroductionCreate> {
  constructor(queryParams: QueryParams = {}) {
    super('introduction', queryParams);
  }
}

export type ServicePannello = {
  id: number;
  sku: string;
  traffico: number|null;
  auto_upgrade_bw: boolean,
  username: string,
  server_fqdn: string|null,
  server_location: string|null,
  backup_location: string|null,
  bwused: number|null,
  bwlimit: number|null,
  id_prodotto: number,
  quotaused: number|null,
  quotalimit: number|null,
  tipo: string,
}

export type Service = {
  dominio: string;
  pannello: ServicePannello|null;
  posta: ServicePannello|null;
  dns: boolean;
  is_registrazioni_dominio: boolean;
  redirect: string;
  pecs: string[]|null;
}

export class ServiceResource extends Resource<Service, Service[]> {
  constructor(queryParams: QueryParams = {}) {
    super('services', queryParams);
  }
}

export type Requirements = {
  id: number;
  nome: string;
  tipo: typeof REQUIREMENTTYPE[number];
}
export class RequirementsResource extends Resource<void, Requirements[]> {
  constructor(queryParams: QueryParams = {}) {
    super('requirements', queryParams);
  }
}
