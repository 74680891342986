<template>
  <div v-for="(stack, k) of wizard.codiciProdottiAddon.stack[wizard.os]" :key="k" class="stack">
    <label class="capitalized">
      <input
        type="radio"
        name="stack"
        :required="required"
        :value="k"
        :checked="modelValue === k"
        @change="$emit('update:modelValue', k)"
      >
      {{ k }}
    </label>
    <p>{{ stack.descrizione }}</p>

    <wizard-price-tag v-if="modelValue !== k" :label="_t('A partire da')" :product="wizard.minPrice(wizard.codiciProdottiAddon.stack[wizard.os][k].codici)"/>

    <div class="stack-products">
      <wizard-question-prodotti
        v-if="modelValue === k"
        v-model="wizard.codiceProdottoStack"
        :prodotti="stack.codici"
        name="codiceProdottoStack"
        required
      />
    </div>
  </div>
</template>

<style scoped>
.stack {
  margin-bottom: 20px;
}

.stack-products {
  padding-left: 30px;
}

.capitalized {
  text-transform: capitalize;
  padding-left: 4px;
}
</style>

<script setup lang="ts">
import { $t as _t } from '@/i18n';
import { type OpzioniStack} from '../wizard.vue';
import { useWizard } from '../composition';
import WizardQuestionProdotti from '../questions/wizard-question-prodotti.vue';
import WizardPriceTag from '../wizard-price-tag.vue';

defineProps<{
  modelValue: OpzioniStack | undefined;
  required?: boolean;
}>();

defineEmits<{
  (name: 'update:modelValue', v: OpzioniStack): void;
}>();

const wizard = useWizard();
</script>
